
import { defineComponent } from "vue";
import AudioComponent from "@/components/AudioComponent.vue";

export default defineComponent({
  name: 'PsalmDetails',
  components: { AudioComponent },
  props: {
    psalm: { type: Object, default: () => ({}) },
    isExpandedProp: { type: Boolean, default: false },
  },
  data() {
    return {
      isExpanded: this.isExpandedProp,
    };
  },
  methods: {
    clickOnExpandButton() {
      this.isExpanded = true;
    },
    clickOnCollapseButton() {
      this.isExpanded = false;
    },
  },
});
