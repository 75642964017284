import axios from "axios";
import LiturgicalCalendar from "@/types/LiturgicalCalendar";

class LiturgicalCalendarDataService {
  // eslint-disable-next-line
  getAll(): Promise<any> {
    return axios
      .get('https://gitlab.com/api/v4/projects/33474676/repository/files/calendrier_liturgique.csv/raw')
      .then((response) => {
        const liturgicalCalendarResults = [] as LiturgicalCalendar[];

        const csvString = response.data;
        const delimiter = ";";

        const rows = csvString.slice(csvString.indexOf("\n") + 1).split("\n");
        rows.pop();

        rows.map(function (row: string) {
          const values = row.split(delimiter);
          
          const newLiturgicalCalendar = {
            celebrationDate: values[0],
            psalmCodes: values[1].split(','),
            isSundayOrHoliday: values[2].toLowerCase() == 'true',
          } as LiturgicalCalendar;
          liturgicalCalendarResults.push(newLiturgicalCalendar);
        });

        return { data: liturgicalCalendarResults };
      })
      // eslint-disable-next-line
      .catch(error => console.log(error));
  }
}

export default new LiturgicalCalendarDataService();
