import Psalm from "@/types/Psalm";
import LiturgicalCalendar from "@/types/LiturgicalCalendar";

/* eslint-disable */
class GetPsalmsOfNextFiveSundaysOrHolidays {
  get(allPsalmsArray: Array<Psalm>, liturgicalCalendar: Array<LiturgicalCalendar>): Array<Psalm> {
    const currentDate = new Date();
    const lastDateString = liturgicalCalendar[liturgicalCalendar.length - 1].celebrationDate;
    const lastDate = new Date(lastDateString);

    let psalmsResults = [] as Psalm[];

    while (psalmsResults.length < 5 && currentDate < lastDate) {
      const currentShortDate = currentDate.toISOString().split('T')[0];
      const celebrationData = liturgicalCalendar.find(celebrationDatePsalmCode => celebrationDatePsalmCode.celebrationDate === currentShortDate && celebrationDatePsalmCode.isSundayOrHoliday);
      
      let result = [] as Psalm[];

      if (celebrationData && celebrationData.psalmCodes) {
        result = celebrationData.psalmCodes.flatMap(psalmCode => {
          return allPsalmsArray.filter(psalm => psalm.psalmCode === psalmCode);
        });
      }

      psalmsResults = psalmsResults.concat(result);

      currentDate.setDate(currentDate.getDate() + 1);
    }
    
    return psalmsResults;
  }
}

export default new GetPsalmsOfNextFiveSundaysOrHolidays();
