import Psalm from "@/types/Psalm";

/* eslint-disable */
class GetRandomPsalm {
  get(allPsalmsArray: Array<Psalm>): Psalm {
    const randomIndex = Math.floor(Math.random() * allPsalmsArray.length);
    
    return allPsalmsArray[randomIndex];
  }
}

export default new GetRandomPsalm();
