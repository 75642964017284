import Psalm from "@/types/Psalm";

/* eslint-disable */
class GetPsalmsOfGivenNumber {
  get(allPsalmsArray: Array<Psalm>, textFilter: string): Array<Psalm> {
    return allPsalmsArray.filter(psalm => psalm.psalmNumber.toUpperCase().indexOf(textFilter) > -1);
  }
}

export default new GetPsalmsOfGivenNumber();
