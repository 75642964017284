
import { defineComponent } from "vue";
import PsalmDetails from "@/components/PsalmDetails.vue";
import PsalmDataService from "@/services/PsalmDataService";
import LiturgicalCalendarDataService from "@/services/LiturgicalCalendarDataService";
import GetPsalmsOfNextFiveSundaysOrHolidays from "@/services/GetPsalmsOfNextFiveSundaysOrHolidays";
import GetPsalmsOfGivenDate from "@/services/GetPsalmsOfGivenDate";
import GetRandomPsalm from "@/services/GetRandomPsalm";
import GetPsalmsOfGivenNumber from "@/services/GetPsalmsOfGivenNumber";
import GetPsalmsOfGivenTitle from "@/services/GetPsalmsOfGivenTitle";
import Psalm from "@/types/Psalm";
import LiturgicalCalendar from "@/types/LiturgicalCalendar";
import ResponseData from "@/types/ResponseData";

export default defineComponent({
  name: "PsalmsList",
  components: { PsalmDetails },
  data() {
    return {
      isNextFiveSundaysOrHolidaysSelected: true,
      isTodaySelected: false,
      isRandomSelected: false,
      isAllPsalmsSearchSelected: false,
      isFilterByDateSelected: false,
      isFilterByNumberSelected: false,
      isFilterByTitleSelected: false,

      psalms: [] as Psalm[],
      celebrationDatePsalmCodes: [] as LiturgicalCalendar[],
      displayedPsalms: [] as Psalm[],
      resultsSentence: '',
    };
  },
  computed: {
    getIsNextFiveSundaysOrHolidaysSelected(): boolean {
      return this.isNextFiveSundaysOrHolidaysSelected;
    },
    getIsTodaySelected(): boolean {
      return this.isTodaySelected;
    },
    getIsRandomSelected(): boolean {
      return this.isRandomSelected;
    },
    getIsAllPsalmsSelected(): boolean {
      return this.isAllPsalmsSearchSelected;
    },
    getIsFilterByDateSelected(): boolean {
      return this.isFilterByDateSelected;
    },
    getIisFilterByNumberSelected(): boolean {
      return this.isFilterByNumberSelected;
    },
    getIsFilterByTitleSelected(): boolean {
      return this.isFilterByTitleSelected;
    },
    getResultsSentence(): string {
      return this.resultsSentence;
    },
  },
  methods: {
    retrievePsalms() {
      return PsalmDataService.getAll()
        .then((response: ResponseData) => {
          this.psalms = response.data;
          return true;
        })
        .catch((e: Error) => {
          // eslint-disable-next-line
          console.log(e);
        });
    },

    retrieveLiturgicalCalendars() {
      return LiturgicalCalendarDataService.getAll()
        .then((response: ResponseData) => {
          this.celebrationDatePsalmCodes = response.data;
          return true;
        })
        .catch((e: Error) => {
          // eslint-disable-next-line
          console.log(e);
        });
    },

    getPsalmsOfNextFiveSundaysOrHolidays() {
      this.isNextFiveSundaysOrHolidaysSelected = true;
      this.isTodaySelected = false;
      this.isRandomSelected = false;
      this.isAllPsalmsSearchSelected = false;
      this.isFilterByDateSelected = false;
      this.isFilterByNumberSelected = false;
      this.isFilterByTitleSelected = false;
      this.resetDateFilter();
      this.resetNumberFilter();
      this.resetTitleFilter();

      this.resultsSentence = 'Les psaumes des 5 prochains dimanches et fêtes :';
      this.displayedPsalms = GetPsalmsOfNextFiveSundaysOrHolidays.get(this.psalms, this.celebrationDatePsalmCodes);
    },

    getPsalmsOfToday() {
      this.isNextFiveSundaysOrHolidaysSelected = false;
      this.isTodaySelected = true;
      this.isRandomSelected = false;
      this.isAllPsalmsSearchSelected = false;
      this.isFilterByDateSelected = false;
      this.isFilterByNumberSelected = false;
      this.isFilterByTitleSelected = false;
      this.resetDateFilter();
      this.resetNumberFilter();
      this.resetTitleFilter();

      const currentDate = new Date();
      const currentShortDate = currentDate.toISOString().split('T')[0];
      this.displayedPsalms = GetPsalmsOfGivenDate.get(this.psalms, this.celebrationDatePsalmCodes, currentShortDate);
      if (this.displayedPsalms.length === 0) {
        this.resultsSentence = 'Il n\'y a pas de psaume aujourd\'hui';
      } else if (this.displayedPsalms.length === 1) {
        this.resultsSentence = 'Le psaume d\'aujourd\'hui :';
      } else {
        this.resultsSentence = 'Les psaumes d\'aujourd\'hui :';
      }
    },

    getRandomPsalm() {
      this.isNextFiveSundaysOrHolidaysSelected = false;
      this.isTodaySelected = false;
      this.isRandomSelected = true;
      this.isAllPsalmsSearchSelected = false;
      this.isFilterByDateSelected = false;
      this.isFilterByNumberSelected = false;
      this.isFilterByTitleSelected = false;
      this.resetDateFilter();
      this.resetNumberFilter();
      this.resetTitleFilter();

      this.resultsSentence = 'Un psaume au hasard :';
      this.displayedPsalms = [];
      this.displayedPsalms[0] = GetRandomPsalm.get(this.psalms);
    },

    getAllPsalms() {
      this.isNextFiveSundaysOrHolidaysSelected = false;
      this.isTodaySelected = false;
      this.isRandomSelected = false;
      this.isAllPsalmsSearchSelected = true;
      this.isFilterByDateSelected = false;
      this.isFilterByNumberSelected = false;
      this.isFilterByTitleSelected = false;
      this.resetDateFilter();
      this.resetNumberFilter();
      this.resetTitleFilter();

      this.resultsSentence = 'Tous les psaumes, ' + this.psalms.length + ' résultats :';
      this.displayedPsalms = this.psalms;
    },

    filterPsalmsByDate() {
      this.isNextFiveSundaysOrHolidaysSelected = false;
      this.isTodaySelected = false;
      this.isRandomSelected = false;
      this.isAllPsalmsSearchSelected = false;
      this.isFilterByDateSelected = true;
      this.isFilterByNumberSelected = false;
      this.isFilterByTitleSelected = false;
      this.resetNumberFilter();
      this.resetTitleFilter();

      this.displayedPsalms = [];
      const nullableInput = document.getElementById("psalm-date-search-input") as HTMLInputElement;
      if (nullableInput === null) {
        this.displayedPsalms = [];
        this.resultsSentence = 'Sélection de date en cours';
      } else if (nullableInput.value === "") {
        this.displayedPsalms = [];
        this.resultsSentence = 'Sélection de date en cours';
      } else {
        const givenDateString = nullableInput.value.toString();
        const givenDate = new Date(nullableInput.value);
        const displayedDate = givenDate.getDate() + '/' + ("0" + (givenDate.getMonth() + 1)).slice(-2) + '/' + givenDate.getFullYear();
        this.displayedPsalms = GetPsalmsOfGivenDate.get(this.psalms, this.celebrationDatePsalmCodes, givenDateString);
        if (this.displayedPsalms.length === 0) {
          this.resultsSentence = 'Il n\'y a pas de psaume le ' + displayedDate;
        } else if (this.displayedPsalms.length === 1) {
          this.resultsSentence = 'Le psaume du ' + displayedDate + ' :';
        } else {
          this.resultsSentence = 'Les ' + this.displayedPsalms.length + ' psaumes du ' + displayedDate + ' :';
        }
      }
    },

    filterPsalmsByNumber() {
      this.isNextFiveSundaysOrHolidaysSelected = false;
      this.isTodaySelected = false;
      this.isRandomSelected = false;
      this.isAllPsalmsSearchSelected = false;
      this.isFilterByDateSelected = false;
      this.isFilterByNumberSelected = true;
      this.isFilterByTitleSelected = false;
      this.resetDateFilter();
      this.resetTitleFilter();

      this.displayedPsalms = [];
      const nullableInput = document.getElementById("psalm-number-search-input") as HTMLInputElement;
      if (nullableInput === null) {
        // eslint-disable-next-line
        console.log('oops');
      } else {
        const textFilter = nullableInput.value.toUpperCase();
        this.displayedPsalms = GetPsalmsOfGivenNumber.get(this.psalms, textFilter);
        if (this.displayedPsalms.length === 0) {
          this.resultsSentence = 'Il n\'y a pas de psaume dont le numéro contient "' + nullableInput.value + '"';
        } else if (this.displayedPsalms.length === 1) {
          this.resultsSentence = 'Le psaume dont le numéro contient "' + nullableInput.value + '" :';
        } else {
          this.resultsSentence = 'Les ' + this.displayedPsalms.length + ' psaumes dont le numéro contient "' + nullableInput.value + '" :';
        }
      }
    },

    filterPsalmsByTitle() {
      this.isNextFiveSundaysOrHolidaysSelected = false;
      this.isTodaySelected = false;
      this.isRandomSelected = false;
      this.isAllPsalmsSearchSelected = false;
      this.isFilterByDateSelected = false;
      this.isFilterByNumberSelected = false;
      this.isFilterByTitleSelected = true;
      this.resetDateFilter();
      this.resetNumberFilter();

      this.displayedPsalms = [];
      const nullableInput = document.getElementById("psalm-title-search-input") as HTMLInputElement;
      if (nullableInput === null) {
        // eslint-disable-next-line
        console.log('oops');
      } else {
        const textFilter = nullableInput.value.toUpperCase();
        this.displayedPsalms = GetPsalmsOfGivenTitle.get(this.psalms, textFilter);
        if (this.displayedPsalms.length === 0) {
          this.resultsSentence = 'Il n\'y a pas de psaume dont l\'antienne contient "' + nullableInput.value + '"';
        } else if (this.displayedPsalms.length === 1) {
          this.resultsSentence = 'Le psaume dont l\'antienne contient "' + nullableInput.value + '" :';
        } else {
          this.resultsSentence = 'Les ' + this.displayedPsalms.length + ' psaumes dont l\'antienne contient "' + nullableInput.value + '" :';
        }
      }
    },

    resetDateFilter() {
      const nullableInput = document.getElementById("psalm-date-search-input") as HTMLInputElement;
      if (nullableInput === null) {
        // eslint-disable-next-line
        console.log('oops');
      } else {
        nullableInput.value = '';
      }
    },

    resetNumberFilter() {
      const nullableInput = document.getElementById("psalm-number-search-input") as HTMLInputElement;
      if (nullableInput === null) {
        // eslint-disable-next-line
        console.log('oops');
      } else {
        nullableInput.value = '';
      }
    },

    resetTitleFilter() {
      const nullableInput = document.getElementById("psalm-title-search-input") as HTMLInputElement;
      if (nullableInput === null) {
        // eslint-disable-next-line
        console.log('oops');
      } else {
        nullableInput.value = '';
      }
    },
  },
  mounted() {
    Promise.all([
        this.retrievePsalms(),
        this.retrieveLiturgicalCalendars(),
      ])
        .then(() => {
          this.getPsalmsOfNextFiveSundaysOrHolidays();
        })
        // eslint-disable-next-line
        .catch(error => console.log(error));
  },
});
