import axios from "axios";
import Psalm from "@/types/Psalm";

class PsalmDataService {
  // eslint-disable-next-line
  getAll(): Promise<any> {
    return axios
      .get('https://gitlab.com/api/v4/projects/33474676/repository/files/psaumes.csv/raw')
      .then((response) => {
        const psalmsResults = [] as Psalm[];

        const csvString = response.data;
        const delimiter = ";";

        const rows = csvString.slice(csvString.indexOf("\n") + 1).split("\n");
        rows.pop();

        rows.map(function (row: string) {
          const values = row.split(delimiter);

          const title = values[4];
          const liturgicalYear = values[5];
          const liturgicalYearLabel = liturgicalYear === 'ABC' ? 'Années A, B et C' : 'Année ' + liturgicalYear;
          const version = Number(values[6]);
          const celebrationLabel = values[1];
          const psalmNumber = values[2];
          const verse = values[3];
          const psalmCode = values[0];

          const gitlabRepositoryAbsolutePath = 'https://gitlab.com/psautier-de-sainte-lucie/psautier-donnees/-/raw/main';
          const versionPath = version === 2 ? '-v2' : '';
          const pngAbsolutePath = gitlabRepositoryAbsolutePath + '/psaumes-png/' + psalmCode + versionPath + '.png';
          const pdfAbsolutePath = gitlabRepositoryAbsolutePath + '/psaumes-pdf-mscz/' + psalmCode + versionPath + '.pdf';
          const msczAbsolutePath = gitlabRepositoryAbsolutePath + '/psaumes-pdf-mscz/' + psalmCode + versionPath + '.mscz';
          const polyphonieAbsolutePath = gitlabRepositoryAbsolutePath + '/psaumes-audio/' + psalmCode + versionPath + '-p' + '.mp3';
          const sopranoAbsolutePath = gitlabRepositoryAbsolutePath + '/psaumes-audio/' + psalmCode + versionPath + '-s' + '.mp3';
          const altoAbsolutePath = gitlabRepositoryAbsolutePath + '/psaumes-audio/' + psalmCode + versionPath + '-a' + '.mp3';
          const tenorAbsolutePath = gitlabRepositoryAbsolutePath + '/psaumes-audio/' + psalmCode + versionPath + '-t' + '.mp3';
          const basseAbsolutePath = gitlabRepositoryAbsolutePath + '/psaumes-audio/' + psalmCode + versionPath + '-b' + '.mp3';

          const newPsalm = {
            title,
            liturgicalYearLabel,
            version,
            celebrationLabel,
            psalmNumber,
            verse,
            psalmCode,
            pngAbsolutePath,
            pdfAbsolutePath,
            msczAbsolutePath,
            polyphonieAbsolutePath,
            sopranoAbsolutePath,
            altoAbsolutePath,
            tenorAbsolutePath,
            basseAbsolutePath,
          } as Psalm;
          psalmsResults.push(newPsalm);
        });

        return { data: psalmsResults };
      })
      // eslint-disable-next-line
      .catch(error => console.log(error));
  }
}

export default new PsalmDataService();
