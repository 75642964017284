import Psalm from "@/types/Psalm";
import LiturgicalCalendar from "@/types/LiturgicalCalendar";

/* eslint-disable */
class GetPsalmsOfGivenDate {
  get(allPsalmsArray: Array<Psalm>, liturgicalCalendar: Array<LiturgicalCalendar>, givenDate: string): Array<Psalm> {
    const celebrationData = liturgicalCalendar.find(celebrationDatePsalmCode => celebrationDatePsalmCode.celebrationDate === givenDate);

    let psalmsResults = [] as Psalm[];

    if (celebrationData) {
      psalmsResults = celebrationData.psalmCodes.flatMap(psalmCode => {
        return allPsalmsArray.filter(psalm => psalm.psalmCode === psalmCode);
      });
    }

    return psalmsResults;
  }
}

export default new GetPsalmsOfGivenDate();
