import Psalm from "@/types/Psalm";

/* eslint-disable */
class GetPsalmsOfGivenTitle {
  get(allPsalmsArray: Array<Psalm>, textFilter: string): Array<Psalm> {
    return allPsalmsArray.filter(psalm => psalm.title.toUpperCase().indexOf(textFilter) > -1);
  }
}

export default new GetPsalmsOfGivenTitle();
